export type Response = { data; total: number }
export type ResponseObject = { data }
export type ResponseArrayObjects = { data }
export type ResponseArrayNumbers = number[]
/** @deprecated */
export type Number = number
/** @deprecated */
export type String = string
/** @deprecated */
export type Object = object

export interface CreateType {
  name: String
  description: String
  roleId: Number[]
  settings: Settings[]
  valueVariants: ValueVariants[]
  applicationId: Number
}

export interface CreateEntitiesPropertiesTypes {
  name: String
  description: String
  settings: Settings[]
  valueVariants: ValueVariants[]
  applicationId: Number
  symbolCode: String
}

export interface CreateEntitiesProperties {
  name: String
  description: String
  symbolCode: String
  typeId: Number
  applicationId: Number
}

export interface CreateUser {
  applicationId: Number
  email: String
  password: String
  roles: Number[]
  regionId: Number
  currencyId: Number
  properties
}

export interface CreateRole {
  name: String
  description: String
  code: Number
  applicationId: Number
  isSystem: Boolean
  properties: Number[]
}
export interface CreateEntitiesTemplates {
  name: String
  description: String
  symbolCode: String
  applicationId: Number
  parentId?: Number
  properties: {
    propertyId: Number
  }[]
  roles: {
    roleId: Number
    typeId: Number
  }[]
  relations: {
    typeId: Number
    onDeleteActionId: Number
    relatedTemplateId: Number
  }[]
}

export interface CreateProperty {
  name: String
  active: Number
  typeId: Number
  applicationId: Number
  symbolCode: Number
  roleId: Number[]
}

export interface CreateLanguage {
  name: String
  symbolCode: Number
  applicationId: Number
}

export interface CreateApplication {
  name: String
  dbName: String
  code: Number
}

export interface CreateContentValue {
  value: String
  entityId: number
  propertyId: Number
}
export interface CreateContext {
  name: String
  description: String
  parentId?: Number
  applicationId: Number
  properties: EntityValue[]
}

export interface EntityValue {
  value: String
  entityId: Number
  propertyId: Number
}

export interface CreateEntitiesTemplate {
  name: String
  description: String
  parentId?: Number
}
export interface CreateContextPropertie {
  name: String
  description: String
  symbolCode: Number
  typeId: Number
  applicationId: Number
}

export interface CreateContextType {
  name: String
  settings: Settings[]
  symbolCode: Number
  applicationId: Number
}
export interface Settings {
  name: String
  value: String
}

export interface ValueVariants {
  value: String
  name: String
}

export interface RelationInGetType {
  symbolCode: String
  entities: {
    users: boolean
    properties: boolean
    relations: RelationInGetType
  }
}

export interface CreateChat {
  name: String
  typeId: Number
  entityId: Number
  users: { id: Number }[]
}

export enum MessageType {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export const ConfirmationStatusColor = {
  0: 'rgba(255,255,255,1)',
  1: 'rgba(149,143,143, 0.5)',
  2: 'rgba(255,234,0,0.5)',
  3: 'rgba(0,255,196,0.5)',
  4: 'rgba(64,255,0,0.5)',
  5: 'rgba(255,115,0,0.5)',
  6: 'rgba(255,0,0,0.5)'
}