import Api from '~/api/wrapper-api'
import { DataProvider, withLifecycleCallbacks } from "react-admin"
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'

import {
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
  CreateEntitiesProperties,
} from '~/utils/types'
import { removeObjectsWithNullOrUndefinedValues } from "~/utils/transformRelation"
import { queryClient } from "~/modules/query-client"
import EntityApi from "~/api/entity-api"

export const entitiesPropertiesDataProvider: DataProvider = {
  async getList(resource: string, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    console.log('entities-properties-data-provider.getList', res.data.rows)
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: string,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: string, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: string,
    { id }: { id: number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const templatesRelations = (() => {
      return {
        templateId: res.data?.templatesRelations
          .filter(templateRelation => templateRelation.templateId)
          .map(templateRelation => templateRelation.templateId),
        sort: res.data?.templatesRelations
          .map(templatesRelation => templatesRelation.sort)
      }
    })()
    console.log('entities-properties-data-provider.getOne', { ...res.data, templatesRelations })
    return { data: { ...res.data, templatesRelations } }
  },
  async create(
    resource: string,
    { data }: { data: CreateEntitiesProperties }
  ): Promise<ResponseObject> {
    const {templatesRelations, ...paramsWithoutNullValues} = removeObjectsWithNullOrUndefinedValues(data)
    const relations = templatesRelations.templateId.map((relation: number) => {
      return { templateId: relation }
    })
    const res = await Api.create(resource, {...paramsWithoutNullValues, templatesRelations: relations})
    return { data: res.data }
  },
  async update(resource: string, params): Promise<ResponseObject> {
    const {templatesRelations, ...paramsWithoutNullValues} = removeObjectsWithNullOrUndefinedValues(params.data)
    const relations = templatesRelations.templateId.map((relation: number) => {
      return { templateId: relation }
    })
    const res = await Api.update(resource, params.id, {...paramsWithoutNullValues, templatesRelations: relations} )
    return { data: res.data }
  },
  async updateMany(resource: string, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: string,
    { id }: { id: number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data.rows }
  },
  async deleteMany(resource: string, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}

export const cycleEntitiesPropertiesDataProvider: DataProvider = withLifecycleCallbacks(
  entitiesPropertiesDataProvider,
  [
    {
      resource: 'entities-properties',
      afterUpdate: async (params, dataProvider: DataProvider) => {
        const { data } = params
        // const cachedDataOld = queryClient.getQueryCache().getAll()
        // console.log('old', cachedDataOld)
        queryClient.removeQueries({
          queryKey: ["entity-templates", String(data.applicationId)] }
        )
        await queryClient.ensureQueryData({
          queryKey: ["entity-templates", String(data.applicationId)],
          queryFn: ({ queryKey }) =>
            EntityApi.getTemplates(Number(queryKey[1])).then(res => res.data),
        })
        // const cachedDataNew = queryClient.getQueryCache().getAll()
        // console.log('new', cachedDataNew)
        return {
          data
        }
      }
    }
  ]
)