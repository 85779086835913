import { useRecordContext, useRedirect } from "react-admin"
import {
  Avatar,
  Conversation,
  ConversationHeader,
  ConversationList,
  Message,
  MessageList
} from "@chatscope/chat-ui-kit-react"
import PersonIcon from "@mui/icons-material/Person"
import React, { useMemo } from "react"
import _ from "lodash"
import { useQuery } from "@tanstack/react-query"
import { asyncEmit, chatSocket } from "~/modules/socket"
import { MessageType } from "~/utils/types"
import moment from "moment"

export const ChatPersonal = () => {
  const record = useRecordContext()
  const redirect = useRedirect()

  const chatId = record.id
  const initiatorId = record.initiatorId

  const initiator = record?.members?.find((user: any) => user.userId === initiatorId)
  const initiatorAvatar = (() => {
    if (initiator) {
      return initiator.user?.properties.find((prop: any) => prop.symbolCode === 'avatar')?.value?.[0]?.url
    } else return null
  })()
  const user = record?.members?.find((user: any) => user.userId !== initiatorId)
  const userAvatar = (() => {
    if (user) {
      return user.user?.properties.find((prop: any) => prop.symbolCode === 'avatar')?.value?.[0]?.url
    } else return null
  })()

  const messagesQueryKey = useMemo(
    () =>
      [
        "chat-messages",
        _.merge(
          {},
          { filter: { chatId } },
          {
            order: {
              field: "createdAt",
              by: "asc"
            }
          }
        )
      ] as const,
    [chatId]
  )
  const { data: messages } = useQuery({
    queryKey: messagesQueryKey,
    queryFn: ({ queryKey }) => {
      return asyncEmit(chatSocket, "chats-messages-list", queryKey[1]).then(
        (r: any) => r.rows
      )
    },
    placeholderData: []
  })

  const message = m => {
    const direction = m.user?.id !== initiatorId ? MessageType.Outgoing : MessageType.Incoming

    const time = moment(m.createdAt).format('HH:mm')

    return (
      <Message
        key={m.id}
        model={{
          direction: direction,
          position: "single"
        }}
      >
        <Message.CustomContent>
          {m.media.length !== 0 && m.media.map((image) => (
            <Message.ImageContent
              key={image.id}
              src={image.url}
              alt={`${image.name}`}
              width={200}
            />
          ))}
          {m.text && <Message.TextContent text={m.text} />}
          <Message.Footer style={{ fontSize: 12, justifyContent: "end", alignItems: "end" }} sentTime={time} />
        </Message.CustomContent>
      </Message>
    )
  }

  return (
    <>
      <div as={ConversationHeader}>
        <ConversationHeader>
          <div
            as={Avatar}
            onClick={() => redirect("show", `users/${initiator.userId}`)}
            style={{ cursor: "pointer" }}
          >
            {initiatorAvatar
              ? <Avatar name="avatar" src={initiatorAvatar} />
              : <PersonIcon fontSize={"large"} />
            }
          </div>
          <ConversationHeader.Content
            onClick={() => {
              redirect("show", `users/${initiator.userId}`)
            }}
            userName={`
            ${initiator?.userId || ''}_
            ${initiator?.user?.properties.find((prop: any) => prop.symbolCode === "name")?.value || "user"}
          `}
          />
        </ConversationHeader>
      </div>
      <ConversationList style={{ display: "flex", justifyContent: "end" }}>
        <Conversation
          onClick={() => {redirect("show", `users/${user.userId}`)}}
          name={`
            ${user?.userId || ''}_
            ${user?.user?.properties.find((prop: any) => prop.symbolCode === 'name')?.value || 'user'}
          `}
          style={{fontWeight: 'bold'}}
        >
          <div as={Avatar}>
            { userAvatar
              ? <Avatar name="avatar" src={userAvatar} />
              : <PersonIcon fontSize={"large"} />
            }
          </div>
        </Conversation>
      </ConversationList>
      <MessageList style={{ height: 740 }}>
        {messages?.map(message)}
      </MessageList>
    </>
  )
}