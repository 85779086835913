import { transformDictToListFromFilter } from './transformRelation'
import moment from "moment"

export const searchFilter = (params): { newFilter: object; query: string } => {
  const { query, ...newFilter } = params
  const hasQuery = query && query !== ''
  if (hasQuery) {
    delete newFilter.query
  }
  return {
    newFilter,
    query: hasQuery ? query : '',
  }
}

export const searchFilterEntity = (params: any, resource: string) => {

  const {
    query,
    properties: propertiesFilter = null,
    userIdsByTypeId: usersFilter,
    relations: relationsFilter,
    ...filter
  } = params

  const newFilter = {
    ...filter,
    ...(query && query !== "" ? { query } : {}),
  }
  for(let key in newFilter) {
    if (['createdAt', 'updatedAt'].includes(key)) {
      const nextDay = moment(filter[key]).add(1, 'days')
      newFilter[key] = { between: [filter[key], nextDay.format('YYYY-MM-DD')] }
    } else if (key === 'email') {
      newFilter[key] = { substring: filter[key] }
    }
  }

  if(resource === 'users' && newFilter.properties) {
    return {
      ...newFilter,
      properties: Object.entries(newFilter.properties).map(([key, value]) => {
        return {
          symbolCode: key,
          value: value.value
        }
      })
    }
  }

  if (usersFilter) {
    newFilter['users'] = [usersFilter]
  }

  const relations: { entity: { symbolCode: string; id: number; } }[] = []
  if (relationsFilter) {
    Object.entries(relationsFilter).map(([key, value]: [string, any]) => {
      if (value.id && Array.isArray(value.id)) {
        value.id.map(v => {
          relations.push({
            entity: {
              symbolCode: key,
              id: v
            }
          })
        })
      } else {
        return (
          relations.push({
            entity: {
              symbolCode: key,
              id: value.id
            }
          })
        )
      }
    })
    newFilter['relations'] = relations
  }

  if (propertiesFilter) {
    return {
      ...newFilter,
      properties: transformDictToListFromFilter(propertiesFilter)
    }
  }
  return newFilter
}

export const getFilter = (
  resource: string,
  filter
  // applicationId: number
): object => {



  if (resource !== 'applications') {
    return {
      ...filter,
      // ...(applicationId !== 0 && { applicationId }),
    }
  } else {
    return filter
  }
}
