import { ResourceRequestParams } from "~/utils/api"
import { instance } from "~/api/instance"

export default class UsersApi {
  static async getList(
    resource: string,
    data: ResourceRequestParams
  ) {
    return await instance.post(
      `/users/relation/request/list`,
      data
    )
  }
}