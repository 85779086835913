import UsersApi from "~/api/users-api"
import { parserParamsForGetList } from "~/utils/dataProviderParams"

export const usersRelationsRequestDataProviders = {
  async getList(
    resource: string,
    params: any
  ): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await UsersApi.getList(resource, { ...filter})
    console.log('users-relations-request', res.data)
    return { data: res.data.rows, total: res.data.count}
  }
}