import { DataProvider } from "react-admin"
import ChatApi from "~/api/chat-api"
import { CHATS_TYPES } from "~/constants"
import { parserParamsForGetList } from "~/utils/dataProviderParams"
import { Response, ResponseObject } from "~/utils/types"

export const baseChatsPersonalProvider: DataProvider = {
  async getList(resource: string, params): Promise<Response> {
    const filter: any = parserParamsForGetList(params, resource)
    filter.filter.typeId = CHATS_TYPES.DIRECT
    const res = await ChatApi.getList({ ...filter })
    console.log("list-chats-personal", res.data)
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: string,
    { id }: {id: number}
  ): Promise<ResponseObject> {
    const res = await ChatApi.getOne(id)
    console.log("getOne-chat-personal", res.data)
    return { data: res.data }
  }
}