import {
  ArrayField,
  BulkDeleteWithConfirmButton,
  ChipField,
  Datagrid,
  DateField,
  Edit, FilterButton,
  FilterForm,
  List, NumberInput,
  Pagination,
  SingleFieldList,
  TextField,
  TextInput
} from "react-admin"
import { ChatPersonal } from "~/components/chat/ChatPersonal"
import React from "react"
import { Stack } from "@mui/material"

export const ChatsPersonalList = () => {

  return (
    <List pagination={<PostPagination />} emptyWhileLoading>
      <ListToolbar />
      <Datagrid
        bulkActionButtons={<PropertyButtons />}
        rowClick={"show"}
        size="medium"
      >
        <TextField label="Chat Id" source="id" />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />
        <ChipField label="Инициатор" source="initiatorId" />
        <ArrayField label="Участники" source={"members"}>
          <SingleFieldList linkType={false}>
            <ChipField source="userId" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  )
}

export const ChatsPersonalShow = () => {
  return (
    <Edit>
      <ChatPersonal />
    </Edit>
  )
}

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />
)

const PropertyButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={[
      <TextInput label="Поиск" source="query" />,
      <TextInput label="Chat Id" source="id" />,
      <NumberInput label="Пользователь" source={"userIdsByTypeId.id"} />,
    ]} />
    <div>
      <FilterButton filters={[
        <TextInput label="Поиск" source="query" />,
        <TextInput label="Chat Id" source="id" />,
        <NumberInput label="Пользователь" source={"userIdsByTypeId.id"} />
      ]} />
    </div>
  </Stack>
)