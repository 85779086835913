import React from "react"
import {
  DatagridConfigurable,
  DeleteButton,
  EditButton,
  FilterButton,
  FilterForm,
  FunctionField,
  List,
  NumberInput,
  Pagination,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  TextField,
  TextInput
} from "react-admin"
import moment from "moment/moment"
import { Stack } from "@mui/material"

const statuses = [
  { id: 1, name: "Новая заявка" },
  { id: 2, name: "Заявка отклонена" },
  { id: 3, name: "Заявка принята" }
]

const rejects = [
  { id: 1, name: "Нет свободных мест" },
  { id: 2, name: "Клиент не подходит под мою спецификацию" },
  { id: 3, name: "В данный момент не осуществляю тренерскую деятельность" }
]

export const UsersRelationRequestList = () => {

  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable rowClick="edit">
        <TextField label="Id" source="id" />
        <FunctionField
          label="Дата создания"
          source="createdAt"
          render={e => `${moment(e.createdAt).format("YYYY-MM-DD")}`}
        />
        <FunctionField
          label="Дата обновления"
          source="updatedAt"
          render={e => `${moment(e.createdAt).format("YYYY-MM-DD")}`}
        />
        <TextField
          label={"Инициатор"}
          source={"initiatorId"}
        />
        <TextField
          label={"Пользователь"}
          source={"userId"}
        />
        <TextField
          label={"Сообщение"}
          source={"introMessage"}
        />
        <SelectField
          label={"Статус"}
          source={"statusId"}
          choices={statuses}
        />
        <SelectField
          label={"Причина отказа"}
          source={"rejectReasonId"}
          choices={rejects}
        />
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={[
      <TextInput label="Поиск" source="query" />,
      <NumberInput label="Id" source="id" />,
      <NumberInput label="Инициатор" source="initiatorId" />,
      <NumberInput label="Пользователь" source="userId" />,
      <SelectInput
        label={"Статус"}
        source={"statusId"}
        choices={statuses}
        optionText="name"
        optionValue="id"
      />
    ]} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={[
        <TextInput label="Поиск" source="query" />,
        <NumberInput label="Id" source="id" />,
        <NumberInput label="Инициатор" source="initiatorId" />,
        <NumberInput label="Пользователь" source="userId" />,
        <SelectInput
          label={"Статус"}
          source={"statusId"}
          choices={statuses}
          optionText="name"
          optionValue="id"
        />
      ]} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)