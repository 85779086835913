import {
  ArrayField, AutocompleteArrayInput,
  AutocompleteInput,
  BulkDeleteWithConfirmButton,
  Create,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FilterButton,
  FilterForm, FunctionField,
  ImageField,
  Labeled,
  List,
  NumberField, NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  SelectColumnsButton,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  TabbedShowLayout,
  TextField,
  TextInput,
  useDataProvider,
  useGetList,
  useNotify,
  useResourceContext
} from "react-admin"
import { Stack } from '@mui/material'
import { useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'
import React, { useEffect, useState } from 'react'
import GoogleMap from '~/components/googleMap/GoogleMap'
import { getFieldForApp, getFieldForEditAndCreate } from '~/utils/getField'
import { resourceList } from '~/modules/resource-list'
import {
  arrayResources, excludeResourcesUserAndChat,
  TrainerIdSelect
} from "~/components/trainerGumSelect/TrainerIdSelect"
import { TableForRelations } from '~/components/trainingProgramDayTable/TableForRelations'
import { parseResource } from '~/modules/parse-resource'
import { EntityName } from '~/constants'
import { DishProductSelect } from '~/components/inputs/DishProductSelect'
import { CreateDishDialog } from '~/components/inputs/CreateDishDialog'
import { CreateExerciseDialog } from '~/components/inputs/CreateExerciseDialog'
import { CreateExerciseGroupDialog } from '~/components/inputs/CreateExerciseGroupDialog'
import { ClientAndGymSelect } from "~/components/inputs/ClientAndGymSelect"
import { CustomTab } from "~/components/tabs/CustomTab"
import { CreateProjectDialog } from "~/components/inputs/CreateProjectDialog"
import { EntityTemplate } from "~/@types/base-app"
import { excludeFilter, InputFilter, RelationInputFilter } from "~/components/inputs/InputFilter"
import { excludeRecourseConfirmationStatusId } from "~/components/fields/ModerationField"
import { ConfirmationStatusColor } from "~/utils/types"


export const EntityForApp = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <List pagination={<PostPagination />} emptyWhileLoading>
      <ListToolbar />
      <DatagridConfigurable
        bulkActionButtons={<PropertyButtons />}
        rowClick={'show'}
        size="medium"
      >
        {
          excludeRecourseConfirmationStatusId.includes(entityName) ?
            <FunctionField
              label="ID"
              source="id"
              render={(record: any) => (
                <span
                  style = {{ backgroundColor: record.properties?.confirmationStatus?.value
                    ? ConfirmationStatusColor[record.properties.confirmationStatus.value]
                    : ConfirmationStatusColor[0]
                  }}
                >
                  {record.id}
                </span>
              )}
            />
            :
            <TextField label={'ID'} source={'id'} />
        }
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />

        {
          !excludeResourcesUserAndChat.includes(entityName) &&
          <ArrayField label={'chat'} source={'chats'}>
            <SingleFieldList linkType={false}>
              <TextField source="id" />
            </SingleFieldList>
          </ArrayField>
        }

        {entityName === 'trainerGym' && (
          <NumberField label="Id родителя" source="parentId" />
        )}

        {
          !excludeResourcesUserAndChat.includes(entityName) &&
          <ArrayField label="User ID" source="$origin.users">
            <SingleFieldList linkType={false}>
              <TextField source="id" />
            </SingleFieldList>
          </ArrayField>
        }


        {entityName === 'nutritionProgramDay' && (
          <ReferenceManyField
            target={'id'}
            label="Программа питания"
            reference={'1:nutritionProgram'}
            source="relations.nutritionProgram"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.name.value" />
              <TextField source="properties.maxCalories.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {entityName === 'trainingProgramDay' && (
          <ReferenceManyField
            target={'id'}
            label="Программа тренеровок"
            reference={'1:trainingProgram'}
            source="relations.trainingProgram"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.name.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {entityName === 'nutritionProgramDayMeal' && (
          <ReferenceManyField
            target={'id'}
            label="День"
            reference={'1:nutritionProgramDay'}
            source="relations.nutritionProgramDay"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.position.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {/* до полей самой сущности, родительские сущности */}
        {template.properties.map(el => getFieldForApp(el))}
        {/* после, дочерние сущности */}

        {entityName === 'nutritionProgramDayMeal' && (
          <ReferenceManyField
            label="Блюда"
            reference={'1:nutritionProgramDayMealDish'}
            target={'id'}
            source="relations.nutritionProgramDayMealDish"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.name.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {entityName === 'nutritionProgramDay' && (
          <ReferenceManyField
            target={'id'}
            label="Прием пищи"
            reference={'1:nutritionProgramDayMeal'}
            source="relations.nutritionProgramDayMeal"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.name.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {entityName === 'nutritionProgram' && (
          <ReferenceManyField
            target={'id'}
            label="Дни программы питания"
            reference={'1:nutritionProgramDay'}
            source="relations.nutritionProgramDay"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.position.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {entityName === 'trainingProgram' && (
          <ReferenceManyField
            target={'id'}
            label="Дни программы тренеровок"
            reference={'1:trainingProgramDay'}
            source="relations.trainingProgramDay"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.position.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {entityName === 'dish' && (
          <ReferenceManyField
            target={'id'}
            label="Продукты"
            reference={'1:dishProduct'}
            source="relations.dishProduct"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.name.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        {(entityName === 'trainingProgramDayExercise' ||
          entityName === 'exercise') && (
          <ReferenceManyField
            target={'id'}
            label="Группа мышц"
            reference={'1:exerciseGroup'}
            source="relations.exerciseGroup"
          >
            <SingleFieldList linkType={false}>
              <TextField source="properties.name.value" />
            </SingleFieldList>
          </ReferenceManyField>
        )}

        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template, templates } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ ml: 2, mr: 2 }}
    >
      <FilterForm filters={[
        <TextInput label="Поиск" source="query" alwaysOn />,
        <TextInput label="ID" source="id" />,
        <DateInput label="Дата создания" source="createdAt" />,
        <DateInput label="Дата изменения" source="updatedAt" />,
        <NumberInput label={"Пользователи"} source={"userIdsByTypeId.id"} />,
        ...template.relations.map(el => RelationInputFilter(el)),
        ...template.properties.filter(el => !excludeFilter.includes(el.symbolCode)).map(el => InputFilter(el)),
      ]} />
      <div>
        <SelectColumnsButton />
        <FilterButton filters={[
          <TextInput label="Поиск" source="query" alwaysOn />,
          <TextInput label="ID" source="id" />,
          <DateInput label="Дата создания" source="createdAt" />,
          <DateInput label="Дата изменения" source="updatedAt" />,
          <NumberInput label={"Пользователи"} source={"userIdsByTypeId.id"} />,
          ...template.relations.map(el => RelationInputFilter(el)),
          ...template.properties.filter(el => !excludeFilter.includes(el.symbolCode)).map(el => InputFilter(el)),
        ]} />
      </div>
    </Stack>
  )
}

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />
)

export const EntityForAppEdit = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  const label = resourceList.find(el => el.name === resource)

  if (!template || !entityName) {
    return null
  }

  return (
    <Edit
      queryOptions={
        [
          'nutritionProgramDayMeal',
          'dish',
          'trainingProgramDay',
          'exercise',
          'projectComponent',
        ].includes(entityName)
          ? { meta: { original: true, getTransformed: true, fullTree: true } }
          : undefined
      }
    >
      <SimpleForm>
        <Labeled label={label.options.label}>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Дата создания">
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления">
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>

        {arrayResources.map((el, index) => {
          return entityName === el && <TrainerIdSelect key={index} />
        })}


        {template.properties.map(el => getFieldForEditAndCreate(el, entityName))}

        {/*FitnesApp*/}

        {entityName === "training" && <ClientAndGymSelect />}

        {entityName === "trainingProgramDay" && (
          <Labeled label="Программы тренеровок">
            <ReferenceInput
              source="relations.trainingProgram"
              reference="1:trainingProgram"
            >
              <AutocompleteInput
                label="Выберите программу тренеровок"
                optionText={`properties.name.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText
                    }
                  }
                })}
                sx={{ minWidth: 300 }}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === "nutritionProgramDay" && (
          <Labeled label="Программы питания">
            <ReferenceInput
              source="relations.nutritionProgram"
              reference="1:nutritionProgram"
            >
              <AutocompleteInput
                label="Выберите программу питания"
                optionText={`properties.name.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText
                    }
                  }
                })}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === "nutritionProgramDayMeal" && (
          <Labeled label="Дни программы питания">
            <ReferenceInput
              source={`relations.nutritionProgramDay`}
              reference="1:nutritionProgramDay"
            >
              <SelectInput
                label="Выберите день"
                source="properties"
                optionText="properties.position.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === "surveyQuestion" && (
          <Labeled label="Опросы">
            <ReferenceInput
              source={`relations.survey`}
              reference="1:survey"
            >
              <SelectInput
                label="Выберите опрос"
                source="properties"
                optionText="properties.title.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === "trainingProgram" && (
          <Labeled label="Дни программы тренеровок">
            <ReferenceArrayInput
              source="relations.trainingProgramDay"
              reference="1:trainingProgramDay"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите позицию дня программы тренировок"
                source="id"
                optionText="properties.position.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        {entityName === "nutritionProgram" && (
          <Labeled label="Дни программы питания">
            <ReferenceArrayInput
              source="relations.nutritionProgramDay"
              reference="1:nutritionProgramDay"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите день программы питания"
                source={"id"}
                optionText="properties.position.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        {entityName === 'survey' && (
          <Labeled label="Вопросы опроса">
            <ReferenceArrayInput
              source="relations.surveyQuestion"
              reference="1:surveyQuestion"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите вопрос"
                source="properties"
                optionText="properties.text.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        {entityName === "trainerGym" && (
          <Labeled label="Название родительского зала">
            <ReferenceInput source="parentId" reference="1:gym">
              <AutocompleteInput
                readOnly={true}
                optionText={`properties.name.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText
                    }
                  }
                })}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === "exercise" && <CreateExerciseGroupDialog />}

        {entityName === "trainingProgramDay" && <CreateExerciseDialog />}

        {entityName === "nutritionProgramDayMeal" && <DishProductSelect />}

        {entityName === "nutritionProgramDay" && (
          <Labeled label="Прием пищи дня программы питания">
            <ReferenceArrayInput
              source={`relations.nutritionProgramDayMeal`}
              reference="1:nutritionProgramDayMeal"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите прием пищи"
                source="properties"
                optionText="properties.name.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        {entityName === "dish" && <CreateDishDialog />}

        {/*MSA Site*/}

        {entityName === "project" && (
          <Labeled label="Состовные части проекта">
            <ReferenceArrayInput
              source="relations.projectComponent"
              reference="3:projectComponent"
            >
              <SelectArrayInput
                label="Выбирите часть"
                source="properties"
                optionText="properties.title.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        {entityName === "projectComponent" && (
          <div style={{ gap: 10, flexDirection: "column" }}>
            {['projectComponentVideo', 'projectComponentSlider', 'projectComponentFormattedText', 'projectComponentQuote', 'projectComponentDevelopers'].map((name, index) => (
              <div
                key={name}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff',
                  padding: '10px',
                  borderRadius: '5px',
                  display:'flex',
                  flexDirection: "column"
                }}
              >
                <CreateProjectDialog entityName={name} />
              </div>
            ))}
          </div>
        )}

        {entityName === "projectComponentSlider" && (
          <Labeled label="Состовные части слайдера">
            <ReferenceArrayInput
              source="relations.projectComponentSliderSlide"
              reference="3:projectComponentSliderSlide"
            >
              <SelectArrayInput
                label="Слайды"
                source="properties"
                optionText="properties.title.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        <TableForRelations />
        <GoogleMap />
      </SimpleForm>
    </Edit>
  )
}

export const EntityForAppShow = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  const { data } = useGetList(resource)

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  const userId = data && data.find(el => el.$origin?.users?.length)

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Общая информация">
          <Labeled label={"ID"}>
            <TextField source="id" />
          </Labeled>
          <Labeled label="Дата создания">
            <DateField label="" source="createdAt" />
          </Labeled>
          <Labeled label="Дата обновления">
            <DateField label="" source="updatedAt" />
          </Labeled>
          {userId && (
            <ArrayField label="User ID" source="$origin.users">
              <SingleFieldList linkType={false}>
                <TextField source="id" />
              </SingleFieldList>
            </ArrayField>
          )}

          {template.properties.map(el => getFieldForApp(el, 'show'))}

        </TabbedShowLayout.Tab>
        {template.relations?.map(relatedEntity => CustomTab(relatedEntity,resource) )}
      </TabbedShowLayout>
    </Show>
  )
}

export const EntityForAppCreate = () => {
  const { applicationId } = useAppContext()
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  const notify = useNotify()
  const dataProvider = useDataProvider()

  if (!template || !entityName) {
    return null
  }

  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({
        ...acc,
        [name]: value,
      })
    )

    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      {
        type: 'error',
      }
    )
  }

  return (
    <Create
      mutationOptions={{ onError }}
      title="Создание сущности"
    >
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        {arrayResources.map((el, index) => {
          return entityName === el && <TrainerIdSelect key={index} />
        })}

        {entityName === 'training' && <ClientAndGymSelect />}

        {entityName === 'trainingProgramDay' && (
          <Labeled label="Программы тренеровок">
            <ReferenceInput
              source="relations.trainingProgram"
              reference="1:trainingProgram"
            >
              <AutocompleteInput
                label="Выберите программу тренеровок"
                optionText={`properties.name.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText,
                    },
                  },
                })}
                sx={{ minWidth: 300 }}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'nutritionProgramDay' && (
          <Labeled label="Программы питания">
            <ReferenceInput
              source="relations.nutritionProgram"
              reference="1:nutritionProgram"
            >
              <AutocompleteInput
                label="Выберите программу питания"
                optionText={`properties.name.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText,
                    },
                  },
                })}
                sx={{ minWidth: 300 }}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'nutritionProgramDayMeal' && (
          <Labeled label="Дни программы питания">
            <ReferenceInput
              source={`relations.nutritionProgramDay`}
              reference="1:nutritionProgramDay"
            >
              <SelectInput
                label="Выберите день"
                source="properties"
                optionText="properties.position.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'surveyQuestion' && (
          <Labeled label="Опросы">
            <ReferenceInput
              source="relations.survey"
              reference="1:survey"
            >
              <AutocompleteInput
                label="Выберите опрос"
                optionText={`properties.title.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText,
                    },
                  },
                })}
                sx={{ minWidth: 300 }}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {template.properties.map(el => getFieldForEditAndCreate(el))}

        {entityName === 'trainerGym' && (
          <Labeled label="Название родительского зала">
            <ReferenceInput source="parentId" reference="1:gym">
              <AutocompleteInput
                optionText={`properties.name.value`}
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText,
                    },
                  },
                })}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'trainingProgramDayExercise' && (
          <Labeled label="Упражнение">
            <ReferenceInput source="relations.exercise" reference="1:exercise">
              <AutocompleteInput
                optionText={`properties.name.value`}
                source="properties"
                filterToQuery={searchText => ({
                  properties: {
                    name: {
                      value: searchText,
                    },
                  },
                })}
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'trainingProgram' && (
          <Labeled label="Выберите позицию дня программы тренировок">
            <ReferenceInput
              source="relations.trainingProgramDay"
              reference="1:trainingProgramDay"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label={false}
                source="properties"
                optionText="properties.position.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'nutritionProgram' && (
          <Labeled label="Дни программы питания">
            <ReferenceInput
              source="relations.nutritionProgramDay"
              reference="1:nutritionProgramDay"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите день"
                source="properties"
                optionText="properties.position.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'nutritionProgramDay' && (
          <Labeled label="Приемы пищи">
            <ReferenceInput
              source="relations.nutritionProgramDayMeal"
              reference="1:nutritionProgramDayMeal"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите прием пищи"
                source="properties"
                optionText="properties.name.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'survey' && (
          <Labeled label="Вопросы опроса">
            <ReferenceInput
              source="relations.surveyQuestion"
              reference="1:surveyQuestion"
              filter={{ specialFilter: entityName }}
            >
              <SelectArrayInput
                label="Выберите вопрос"
                source="properties"
                optionText="properties.text.value"
              />
            </ReferenceInput>
          </Labeled>
        )}

        {entityName === 'exercise' && <CreateExerciseGroupDialog />}

        {entityName === 'nutritionProgramDayMeal' && <DishProductSelect />}

        {entityName === 'dish' && <CreateDishDialog />}

        {entityName === 'trainingProgramDay' && <CreateExerciseDialog />}

        {/*MSA Site*/}

        {entityName === "project" && (
          <Labeled label="Состовные части проекта">
            <ReferenceArrayInput
              source="relations.projectComponent"
              reference="3:projectComponent"
            >
              <SelectArrayInput
                label="Выбирите часть"
                source="properties"
                optionText="properties.title.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}

        {entityName === "projectComponent" && (
          <div style={{ gap: 10, flexDirection: "column" }}>
            {['projectComponentVideo', 'projectComponentSlider', 'projectComponentFormattedText', 'projectComponentQuote', 'projectComponentDevelopers'].map((name, index) => (
              <div
                key={name}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#d0d0d0',
                  padding: '10px',
                  borderRadius: '5px',
                  display:'flex',
                  flexDirection: "column"
                }}
              >
                <CreateProjectDialog entityName={name} />
              </div>
            ))}
          </div>
        )}

        {entityName === "projectComponentSlider" && (
          <Labeled label="Состовные части слайдера">
            <ReferenceArrayInput
              source="relations.projectComponentSliderSlide"
              reference="3:projectComponentSliderSlide"
            >
              <SelectArrayInput
                label="Слайды"
                source="properties"
                optionText="properties.title.value"
              />
            </ReferenceArrayInput>
          </Labeled>
        )}
      </SimpleForm>
    </Create>
  )
}

const PropertyButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="ID" source="id" />,
  <TextInput label="Адрес" source="properties.address.value" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

const SearchForExerciseGroup = ({ label }) => {
  const filterToQuery = searchText => ({
    properties: {
      name: {
        value: searchText,
      },
    },
  })

  return (
    <ReferenceInput
      label={label}
      source="relations[0].exerciseGroup.id"
      reference="exerciseGroup"
      alwaysOn
    >
      <AutocompleteInput
        label="Группа мышц"
        optionText={`properties.name.value`}
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>
  )
}

const SearchForNutritionMealDish = ({ label }) => {
  const filterToQuery = searchText => ({
    properties: {
      name: {
        value: searchText,
      },
    },
  })

  return (
    <ReferenceInput
      label={label}
      source="relations[0].nutritionProgramDayMealDish.id"
      reference="nutritionProgramDayMealDish"
      alwaysOn
    >
      <AutocompleteInput
        label="Название блюда"
        optionText={`properties.name.value`}
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>
  )
}

const SearchForNutritionDayMeal = ({ label }) => {
  const filterToQuery = searchText => ({
    properties: {
      name: {
        value: searchText,
      },
    },
  })

  return (
    <ReferenceInput
      label={label}
      source="relations[0].nutritionProgramDayMeal.id"
      reference="nutritionProgramDayMeal"
      alwaysOn
    >
      <AutocompleteInput
        label="Прием пищи"
        optionText={`properties.name.value`}
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>
  )
}

const SearchForNutrition = ({ label }) => {
  const filterToQuery = searchText => ({
    properties: {
      name: {
        value: searchText,
      },
    },
  })

  return (
    <ReferenceInput
      label={label}
      source="relations[0].nutritionProgramDay.id"
      reference="nutritionProgramDay"
      alwaysOn
    >
      <AutocompleteInput
        label="День программы питания"
        optionText={`properties.position.value`}
        filterToQuery={filterToQuery}
      />
    </ReferenceInput>
  )
}

// filter for exercise page
const postFiltersExercise = [
  <TextInput label="Поиск" source="query" />,
  <TextInput label="ID" source="id" />,
  <TextInput label={'Упражнение'} source="properties.name.value" />,
  <SearchForExerciseGroup label="Группа мышц" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

const postFiltersNutritionDish = [
  <TextInput label="Поиск" source="query" />,
  <TextInput label="ID" source="id" />,
  <TextInput label="Название" source="properties.name.value" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

const postFiltersNutritionMeal = [
  <TextInput label="Поиск" source="query" />,
  <TextInput label="ID" source="id" />,
  <TextInput label="Название приема пищи" source="properties.name.value" />,
  <SearchForNutritionMealDish label="Название блюда" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

const postFiltersNutritionDay = [
  <TextInput label="Поиск" source="query" />,
  <TextInput label="ID" source="id" />,
  <TextInput label="Позиция" source="properties.position.value" />,
  <SearchForNutritionDayMeal label="Прием пищи" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

const postFiltersNutrition = [
  <TextInput label="Поиск" source="query" />,
  <TextInput label="ID" source="id" />,
  <TextInput label="Название" source="properties.name.value" />,
  <SearchForNutrition label="День программы питания" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]
