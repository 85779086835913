import { EntityName } from '~/constants'
import { instance } from './instance'
import { ResourceRequestParams } from '~/utils/api'
import { RelationInGetType } from '~/utils/types'

export default class EntityApi {
  static async getTemplates(applicationId:number) {
    if  (applicationId === 3) {
      return await instance.get<EntityTemplate[]>(
        `/content-entities-templates/list/detailed/${applicationId}`
      )
    } else {
      return await instance.get<EntityTemplate[]>(
        `/entities-templates/list/detailed/${applicationId}`
      )
    }
  }

  static async getList(
    applicationId: number,
    entityName: EntityName,
    data: ResourceRequestParams = {}
  ) {

    if  (applicationId === 3) {
      return await instance.post(
        `/content-entities/${applicationId}/${entityName}/list`,
        data
      )
    } else {
      return await instance.post(
        `/entities/${applicationId}/${entityName}/list`,
        data
      )
    }

  }

  static async getOne(
    applicationId: number,
    entityName: EntityName,
    id: number,
    pull: {
      users: boolean
      properties: boolean
      children: boolean
      parent: boolean
      relations: RelationInGetType[]
    }
  ) {
    const queryParams = Object.entries(pull).reduce((accum: string[], [key, value]) => {
      if (typeof value !== 'undefined') {
        accum.push(`${key}=${encodeURIComponent(JSON.stringify(value))}`)
      }
      return accum
    }, [])
    const queryParamsString = queryParams.length
      ? '?' + queryParams.join('&')
      : ''

    if  (applicationId === 3) {
      return await instance.get(
        `/content-entities/${applicationId}/${entityName}/${id}${queryParamsString}`
      )
    } else {
      return await instance.get(
        `/entities/${applicationId}/${entityName}/${id}${queryParamsString}`
      )
    }

  }

  static async create(
    applicationId: number,
    entityName: EntityName,
    params: Object
  ) {
    if  (applicationId === 3) {
      return await instance.post(`/content-entities/${applicationId}/${entityName}`, {
        ...params,
      })
    } else {
      return await instance.post(`/entities/${applicationId}/${entityName}`, {
        ...params,
      })
    }
  }

  static async update(
    applicationId: number,
    entityName: EntityName,
    id: number,
    params: RawEntity
  ) {
    if  (applicationId === 3) {
      return await instance.patch(
        `/content-entities/${applicationId}/${entityName}/${id}`,
        { ...params }
      )
    } else {
      return await instance.patch(
        `/entities/${applicationId}/${entityName}/${id}`,
        { ...params }
      )
    }
  }

  static async delete(
    applicationId: number,
    entityName: EntityName,
    id: number
  ) {
    if  (applicationId === 3) {
      return await instance.delete(
        `/content-entities/${applicationId}/${entityName}/${id}`
      )
    } else {
      return await instance.delete(
        `/entities/${applicationId}/${entityName}/${id}`
      )
    }

  }
}
